@use '@angular/material' as mat;
@include mat.core();

@import 'theme-reset';
.app{
  &.clvs{
    @import "skins/clvs";
    @include mat.all-component-colors($clvs-theme);
    @include theme-reset($clvs-theme);
  }
}
