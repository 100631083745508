/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "app/theme/styles/spaces";
@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";

.table-actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.w-100 {
  width: 100% !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.flex-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.table-container {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 20px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(97 161 225 / 70%);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0082c0;
}
