﻿/* For use in src/lib/core/theming/_palette.scss */
$md-clvscolor: (
  50 : #e4f0f7,
  100 : #bbd9ec,
  200 : #8dc0df,
  300 : #5fa7d2,
  400 : #3d94c8,
  500 : #1b81be,
  600 : #1879b8,
  700 : #146eaf,
  800 : #1064a7,
  900 : #085199,
  A100 : #c6e0ff,
  A200 : #93c4ff,
  A400 : #60a8ff,
  A700 : #479aff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);



$clvs-primary: mat.define-palette($md-clvscolor, 700, 50, 900);
$clvs-accent: mat.define-palette(mat.$grey-palette, 300, 100, 700);
$clvs-warn: mat.define-palette(mat.$red-palette, 500, A200, 800);

$clvs-theme: mat.define-light-theme((
  color: (
    primary: $clvs-primary,
    accent: $clvs-accent,
    warn: $clvs-warn,
  )
));
